import { ConfirmResetPasswordInput, ConfirmSignInInput, ResetPasswordInput, SignInInput } from 'aws-amplify/auth';
import { Observable } from 'rxjs';

import { AuthSignInStep } from '../models/auth.model';

export abstract class AuthUseCase {
  abstract get authStep$(): Observable<AuthSignInStep>;
  abstract get token$(): Observable<string>;
  abstract get username$(): Observable<string>;
  abstract set nextStep(step: AuthSignInStep);

  abstract signIn(params: SignInInput): Observable<never>;
  abstract confirmSignIn(params: ConfirmSignInInput): Observable<never>;
  abstract signOut(): Observable<never>;
  abstract resetPassword(params: ResetPasswordInput): Observable<never>;
  abstract confirmResetPassword(params: ConfirmResetPasswordInput): Observable<never>;
}
